<template>
  <b-modal id="popup-common-step" ref="popupCommonStep" centered="centered" :title="(step.id ? 'Modifier' : 'Ajouter') + ` une étape`" @ok="create">
    <div v-if="isLoadingCommonStepsList || isLoadingCommonStepsListArchived">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>

    <validation-observer v-else ref="formStep">
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
            <validation-provider #default="{ errors }" name="Libellé" rules="required">
              <b-form-input
                :state="errors.length > 0 ? false : null"
                id="label"
                v-model="step.label"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
                v-on:keyup.enter="create"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!archive">
        <b-col cols="12" class="mb-1">
          <b-text style="font-size: 0.9rem">Tâches</b-text>
          <draggable tag="div" :list="step.missions" @end="updateIndex">
            <div v-for="(mission, index) in step.missions" :key="mission.mission.id + ' ' + index" class="missionList">
              <span>{{ mission.mission.label }}</span>
              <div>
                <feather-icon icon="Trash2Icon" @click="deleteMissionLocal(mission.mission.id, mission.mission.isCommon, index)" class="pe-pointer" />
                <feather-icon icon="EditIcon" @click="popupCreateMission(mission.mission.id)" />
              </div>
            </div>
          </draggable>
          <b-btn @click="popupCreateMission('', false)" class="" variant="outline-primary w-100 d-flex justify-content-between">
            <span>Ajouter une tâche</span>
            <feather-icon icon="PlusIcon" />
          </b-btn>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button class="float-left" v-if="archive" variant="outline-danger" @click="deleteStepLocal(step.id, step.label)"
          ><feather-icon icon="Trash2Icon"
        /></b-button>
        <b-button class="float-left" v-else variant="outline-danger" @click="archiveStepLocal(step.id, step.label)"
          ><feather-icon icon="ArchiveIcon"
        /></b-button>
        <b-button class="float-right" v-if="!archive" variant="primary" @click="ok()">{{ step.id ? "Modifier" : "Ajouter" }}</b-button>
        <b-button class="float-right" v-if="archive" variant="warning" @click="restoreStepLocal(step.id, step.label)">Restaurer</b-button>
        <b-button class="float-right mr-1" variant="outline-primary" @click="cancel()">Annuler</b-button>
      </div>
    </template>
    <popup-mission ref="popupCommonMission" @popupMissionClose="addMission" @updateMission="updateMission" />
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure, localize } from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
import PopupMission from "@/components/mission/PopupMissionFromCommonMission";
import draggable from "vuedraggable";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      isCommonStep: false,
      step: {
        id: 0,
        commonStepId: null,
        label: "",
        isCommon: false,
        missions: [],
      },
      archive: false,
    };
  },
  methods: {
    addMission(data) {
      this.step.missions.push({ mission: { label: data.data.label, id: data.data.id } });
    },
    updateMission(data) {
      this.step.missions.splice(
        this.step.missions.findIndex((elem) => elem.mission.id === data.data.id),
        1,
        { mission: { label: data.data.label, id: data.data.id } }
      );
    },
    popupCreateCommonStepEvent(id) {
      this.isCommonStep = true;
      this.archive = false;
      if (id > 0) {
        this.fetchStep(id).then((res) => {
          this.step = res;
        });
      } else {
        this.initializeForm();
      }
    },
    updateIndex() {
      const missions = [];
      for (let i = 0; i < this.step.missions.length; i++) {
        const mission = this.step.missions[i];
        missions.push({ id: mission.mission.id, index: i });
      }
      this.updateMissionIndex(missions);
    },
    popupCreateCommonStepArchivedEvent(id) {
      this.isCommonStep = true;
      this.archive = true;
      if (id) {
        this.step = this.$store.getters.commonStepsListArchived.find((el) => el.id == id);
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.step = {
        commonStepId: null,
        label: "",
        isCommon: false,
        missions: [],
      };
    },
    deleteMissionLocal(id, isCommon, index) {
      if (isCommon) {
        this.step.missions.splice(index, 1);
      } else {
        this.deleteMission(id).then(() => {
          this.step.missions.splice(index, 1);
        });
      }
    },
    deleteStepLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous ne pourrez plus restaurer cette étape en consultant la liste de vos archives.", {
          title: `Êtes-vous sûr de vouloir supprimer l'étape' ${label} ?`,
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteStep(id).then(() => {
              this.$emit("deleteStepInSteps", id);
            });
            this.$nextTick(() => {
              this.$bvModal.hide("popup-common-step");
            });
          }
        });
    },
    archiveStepLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous pourrez restaurer cette étape en consultant la liste de vos archives.", {
          title: `Êtes-vous sûr de vouloir archiver l'étape ${label} ?`,
          size: "sm",
          okVariant: "warning",
          okTitle: "Archiver",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.archiveStep(id).then(() => {
              this.$emit("deleteStepInSteps", id);
            });
            this.$nextTick(() => {
              this.$bvModal.hide("popup-common-step");
            });
          }
        });
    },
    restoreStepLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous pourrez restaurer cette étape en consultant la liste de vos archives.", {
          title: `Êtes-vous sûr de vouloir restauré l'étape ${label} ?`,
          size: "sm",
          okVariant: "warning",
          okTitle: "Restaurer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.restoreStep(id);
            this.$nextTick(() => {
              this.$bvModal.hide("popup-common-step");
            });
          }
        });
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formStep.validate().then((success) => {
        if (success) {
          const missions = this.step.missions.map((elem) => {
            return elem.mission.id;
          });
          if (!this.step.id) {
            // création d'une étape
            this.createStep({ label: this.step.label, isCommon: true }).then((res) => {
              this.fetchMissionsList().then(() => {
                this.updateMissionStep({ stepId: res.id, missions: missions });
              });
              this.$nextTick(() => {
                this.$bvModal.hide("popup-common-step");
                this.initializeForm();
              });
            });
          } else {
            // édition d'une étape
            this.updateStep({ label: this.step.label, isCommon: true, id: this.step.id }).then((res) => {
              this.fetchMissionsList().then(() => {
                this.updateMissionStep({ stepId: res.id, missions: missions });
              });
              this.$nextTick(() => {
                this.$bvModal.hide("popup-common-step");
                this.initializeForm();
              });
            });
          }
        }
      });
    },
    popupCreateMission(id) {
      this.$bvModal.show("popup-common-mission");
      this.$refs["popupCommonMission"].popupCreateMissionFromStepEvent(id);
    },
    ...mapActions([
      "createStep",
      "fetchMissionsList",
      "updateStep",
      "updateMissionStep",
      "updateMissionIndex",
      "deleteStep",
      "deleteMission",
      "restoreStep",
      "fetchStep",
      "archiveStep",
      "fetchCommonStepsList",
      "fetchCommonStepsListArchived",
    ]),
  },
  computed: {
    ...mapGetters(["isCreatingAccessMethodType", "stepsList", "commonStepsList", "isLoadingCommonStepsList", "isLoadingCommonStepsListArchived"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    PopupMission,
    draggable,
  },
  directives: {
    Ripple,
  },
};
</script>

<style scoped lang="scss">
.missionList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #0096c7;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  margin: 5px 0;
  cursor: grab;

  div svg {
    cursor: pointer;

    &:nth-child(2) {
      margin-left: 5px;
    }
  }
}
</style>
